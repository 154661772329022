.department-nav {
    display: flex;
    width: 100%;
    height: fit-content;
    margin-top: 10px;
    position: initial;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: red; */
    border-top: 1px black solid;
    overflow-y: scroll;
}

.department-nav ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    min-width: 100%;
    height: fit-content;
    margin-left: -10px;
    padding-inline-start: 40px;
}

.department-nav ul li {
    padding: 4px 10px;
    border-radius: 5px;
}

.department-selected {
    background-color: var(--darkGreen);
    color: white;
    transition: .4s;
}

@media screen and (min-width: 768px) { 
    .department-nav{
        overflow-y:visible;
        border: 0;
        border-bottom: 1px solid black;
        padding-bottom: 20px;
    }
    .department-nav ul{
        /* width: 100vw;
        min-width: 100vw; */
        justify-content: space-around;
        padding-inline-start: 0px;
    }
    .department-nav li:hover {
        cursor: pointer;
        text-shadow: 0px 0px 15px var(--darkGreen);
        transition: .2s;
    }
}