.nav-closed {
    position: absolute;
    left: -100%;
    transition: 600ms
}

.active {
    left: 0;
    transition: 400ms
}
.darken-background {
    background-color: rgba(0, 0, 0, .7);
    transition: 800ms
}


.nav-container {
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 100%;

}

.faX {
    font-size: 20px;
    color: rgb(255, 255, 255);
    margin: 15px;
    position: absolute;
}
.fade-out {
    opacity: 0;
}
.fade-in {
    opacity: 1;
    transition: 350ms;
    transition-delay: 600ms;
}

.click-to-close {
width: 25%;
height: 100%;
position:absolute;
right: 0;
display: flex;
justify-content: flex-end;
}



nav {
    width: 75%;
    height: 100%;
    background-color: white;
    text-align: center;
    overflow-y: scroll;
    position: absolute;
}

.nav-header {
    height: 20%;
    width: 100%;
    background-color: var(--lightGreen);
    text-align: center;
    position: sticky;
    top: 0;

}
.nav-header div {
    padding-top: 5vh;
}
h1 {
    font-family: 'Academy Engraved LET';
    color: white;
    font-weight: 200;
    font-size: 28px;
}


.nav-a-small {
    font-family: 'Copperplate';
    font-weight: 400;
    font-size: x-large;
    line-height: 120px;
}

a {
    text-decoration: none;
    color: black;
}

.departments {
    padding-top: 5px;
    padding-bottom: 10px;
    border-top: 2px solid var(--lightGreen);
    border-bottom: 2px solid var(--lightGreen);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* min-height: 100px; */
    align-items: center;
}
.departments h2 {
    width: 100%;
    text-align: center;
}
.departments ul {
    list-style-type:none;
    margin-left: -90px;
    line-height: 25px;
    text-align: left;
}
.departments ul li:hover {
    color: var(--lightGreen);
}

.departments-closed {
    height: 0;
    overflow: hidden;
    transition: 500ms;
}
.departments-open {
    height: 150px;
    overflow: hidden;
    transition: 500ms;
}

.nav-end-container a {
    line-height: 80px;
}

nav a {
    padding: 2px 3px;
}

nav a:hover {
    background-color: var(--lightGreen);
}

.line {
    width: 50px;
    height: 2px;
    background-color: var(--lightGreen);
    margin: auto;
}

@media screen and (min-width: 768px) {
    .departments-open {
        height: fit-content;
    }
    .departments-open::after {
        margin-left: 10vw;
        transition: 0ms;
      }
    nav a {
        padding: 30px;
    }
    nav ul {
        overflow: hidden;
    }
    
}
