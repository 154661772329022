.department-section-container {
    height: 250px;
    overflow: hidden;
    position: relative;
}

.department-img-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

img.category-img {
    width: 100%;
}


.title-container {
    position: absolute;
    height: 25%;
    width: 60%;
    bottom: 50px;
    display:  flex;
    align-items: center;
    justify-content: center;
    background-color: var(--transparentGreen);
}

.department-description {
    line-height: 25px;
    margin: -40px 50px 100px 50px;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    
}

.brands {
    background-image: url(../../assets/images/backgrounds/wood.webp);
    background-size: 250px;
    min-height: 500px;
    height: fit-content;
    /* filter: brightness(50%); */
    overflow: hidden;
    padding-bottom: 100px;
    margin-top: 60px;
}


.brand-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* position: absolute; */
    /* filter: brightness(150%); */
}

.brand-container img {
    margin-bottom: 50px;
}

.l-screen {
    display: none;
}

@media screen and (min-width: 768px) { 
    .d-s-c {
        display: flex;
        align-items: flex-start;
        width: 30%;
        /* margin: 10px 30px 0px 0px; */
        min-width: 300px;
        flex-wrap: wrap;
    }
    .department-section-container{
        margin: auto;
        
    }
    .department-img-container img{
        height: auto;
        /* width: auto; */
    }
    .department-description {
        text-align: center;
        font-size: 15px;
        height:150px;
        margin: 25px 50px 25px 50px;
    }
    .departments-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 0px 15%
    }
    .title-container {
        width: 100%;
        bottom: 0;
    }
    .brands{
        min-height: 0px;
        margin: auto;
        padding: 50px;

    }
    .brands img{
        width: 200px;
        margin: 30px
    }

    .l-screen {
        display: flex;
    }
    .s-screen {
        display: none;
    }

}